<template>
    <v-container fluid class="px-8 mb-8">
        <!-- 课程包基本内容和状态 -->
        <v-card class="course-info mb-12" elevation="2">
            <v-img
                :src="getImgUrl(topic.cover, defaultImg4Topic)"
                height="125"
                width="200"
                max-width="200"
                class="grey darken-4 me-5"
            ></v-img>

            <div class="course-desc">
                <div class="desc-col">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium">
                            课程包名称：
                        </div>
                        <div class="text">{{ topic.name }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium">
                            课程包说明：
                        </div>
                        <div class="text">{{ getText(topic.desc, "暂无说明") }}</div>
                    </div>
                </div>
            </div>
        </v-card>

        <!-- 分组列表 -->
        <draggable
            :disabled="disabledGroupList"
            v-model="groups"
            v-if="groups.length > 0"
        >
        <v-card
            class="course-group-wrapper mb-5"
            elevation="2"
            v-for="group in groups"
            :key="group.group_id"
        >
            <!-- 课程分组信息：Cover、标题、说明 -->
            <div class="group-info-wrapper">
                <div class="group-img">
                    <v-img
                        :src="getImgUrl(group.cover, defaultImg4Group)"
                        height="120"
                        width="120"
                        max-width="120"
                        class=""
                        contain
                    ></v-img>
                </div>

                <div class="group-info">
                    <div class="group-desc" @click.stop="toggleCourseList(group)">
                        <div class="mb-4 desc-row">
                            <div class="desc-row-name">标题：</div>
                            <div class="text-h5 font-weight-light">
                                {{ group.name }}
                            </div>
                        </div>
                        <div class="desc-row">
                            <div class="desc-row-name">说明：</div>
                            <div class="text-h5 font-weight-light">
                                {{ group.desc }}
                            </div>
                        </div>
                    </div>
                    <div class="btn-group">
                        <v-btn
                            class="ml-4"
                            fab
                            outlined
                            x-small
                            color="grey lighten-1"
                            @click.stop="toggleCourseList(group)"
                        >
                            <v-icon dark size="24" color="grey lighten-1" v-if="group.isShow"> mdi-chevron-up </v-icon>
                            <v-icon dark size="24" color="grey lighten-1" v-else> mdi-chevron-down </v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>

            <!-- 分组课程列表 -->
            <div class="group-course-list" v-if="group.isShow">
                <div class="course-list">
                    <div class="list-group-headers">
                        <div class="index">序号</div>
                        <div class="type">类型</div>
                        <div class="name">课程名称</div>
                        <div class="desc">课程说明</div>
                        <!-- <div class="status">状态</div> -->
                        <div class="op">对学生开放</div>
                    </div>
                    <draggable
                        :list="group.courses"
                        :disabled="disabledCourseList"
                        class="list-group"
                        ghost-class="ghost"
                    >
                        <div
                            class="list-group-item"
                            v-for="(item, index) in group.courses"
                            :key="item.cid"
                        >
                            <div class="group-item">
                                <div class="index">{{ index + 1 }}</div>
                                <div class="type">
                                    <v-chip :color="getTypeColor(item.course_type)" small dark >
                                        {{ getTypeFirstLetter(item.course_type) }}
                                    </v-chip>
                                </div>
                                <div class="name">
                                    <div class="active-name"
                                        @click.stop="goto(item.cid)"
                                    >
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div class="desc">
                                    {{ getText(item.desc, "暂无说明") }}
                                </div>
                                <!-- <div class="status">
                                    <v-chip :color="item.status == 1 ? 'light-green lighten-1' : 'grey lighten-1'" small dark >
                                        {{ getStatus(item.status) }}
                                    </v-chip>
                                </div> -->
                                <div class="op">
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs" 
                                                v-on="on"
                                                class="mx-2"
                                                outlined
                                                x-small
                                                icon
                                                height="32"
                                                width="32"
                                                :color="item.is_unlocked ? 'grey' : 'orange'"
                                                @click.stop="toggleIsUnLocked(item)"
                                            >
                                                <v-icon dark v-if="item.is_unlocked"> mdi-lock-open-variant-outline </v-icon>
                                                <v-icon dark v-else> mdi-lock </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>锁定/解锁</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </v-card>
        </draggable>

    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { getText, getImgUrl, getTypeFirstLetter } from "@/utils/util";
import { getTypeColor } from '@/utils/common';
import {
    getAdminTopic,
    getAdminGroupList,
    getAdminGroupCourseRelations4Class,
    setAdminClassCourseUnlocked,
} from "@/api/admin";

export default {
    name: "TopicGroup",

    data() {
        return {
            defaultImg4Topic: require("@/assets/images/admin/default-cover.jpeg"),
            defaultImg4Group: require("@/assets/images/admin/default-group.png"),

            classId: "",
            topic: {},
            groups: [
                // 结构说明：
                // {
                //     id: '',
                //     group_id: '',
                //     topic_id: '',
                //     name: '',
                //     desc: '',
                //     cover: '',
                //     index: 0,
                //     courses: [], // 每个分组中的课程列表
                // }
            ],
            disabledGroupList: true,
            disabledCourseList: true,
            dragging: false,

            fromPath: '',
        };
    },
    mounted() {
    },
    activated() {
        let topicId = ''
        if (this.$route.query.id) {
            topicId = this.$route.query.id
        }
        if (this.$route.query.classId) {
            this.classId = this.$route.query.classId
        }

        // 如果进入过ide页面后，再返回到这里，不会被触发！！！
        // if (this.fromPath != '/education-mgr/class-list/class/topic/course') {
            this.loadData(topicId)
        // }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // 通过 vm 访问组件实例,将值传入fromPath
            vm.fromPath = from.path;
        });
    },
    methods: {
        getText,
        getImgUrl,
        getTypeColor,
        getTypeFirstLetter,
        getStatus(status) {
            if (status == 0) {
                return "待发布"
            } else if (status == 1) {
                return "已发布"
            }
        },
        goto(id) {
            this.$router.push({
                path: "/education-mgr/class-list/class/topic/course",
                query: { id: id, classId: this.classId },
            });
        },
        async loadData(topicId) {
            // 获取主题信息
            getAdminTopic(topicId).then((response) => {
                // console.log(response.data);
                this.topic = response.data;

            }).catch(function (err) {
                console.log(err);
            });

            // 获取主题分组列表
            let groups = await getAdminGroupList(topicId)
            this.groups = groups.data

            // console.log(this.groups)
            // for (let i = 0; i < this.groups.length; i++) {
            //     this.groups[i].courses = []
            // }

            for (let i = 0; i < this.groups.length; i++) {
                // console.log(this.groups[i])
                let group = this.groups[i]
                // console.log(group)
                let courses = await getAdminGroupCourseRelations4Class({
                    id: group.group_id,
                    classId: this.classId
                })
                // console.log(courses)
                this.groups[i].courses = courses.data
                this.groups[i].isShow = false
            }
            if (this.groups.length >= 1) {
                this.groups[0].isShow = true
            }

            // console.log(this.groups)
            this.$forceUpdate()
        },
        toggleCourseList(group) {
            // 如果当前列表已经展开，直接关闭
            // 如果当前列表关闭状态，先将其他分组列表关闭，再将其打开
            if (group.isShow) {
                group.isShow = false

            } else {
                for (let i = 0; i < this.groups.length; i++) {
                    this.groups[i].isShow = false
                }
                group.isShow = true
            }
            this.$forceUpdate()
        },
        toggleIsUnLocked(item) {
            item.is_unlocked = !item.is_unlocked

            setAdminClassCourseUnlocked({
                classId: this.classId,
                cid: item.cid,
                isUnlocked: item.is_unlocked
            })
        }
    },
    components: {
        draggable,
    },
};
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.ghost {
    opacity: 0.5;
    background: #dddedf;
}

.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
        }
    }
}

.operate {
    // height: 125px;
    height: 100%;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-group-wrapper {
    display: flex;
    flex-direction: column;

    .group-info-wrapper {
        // flex: 4;
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        align-items: center;
        position: relative;
        background-color: rgb(247, 247, 247);

        .group-img {
            // margin-right: 20px;
            padding: 20px;
            // background-color: #fff;
            // border-bottom: 1px solid #efefef;
            // &:hover {
            //     cursor: move;
            // }
        }

        .group-info {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .group-desc {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-left: 15px;
                min-height: 120px;
                &:hover {
                    // cursor: move;
                    cursor: pointer;
                }
                .desc-row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;

                    .desc-row-name {
                        height: 28px;
                        width: 50px;
                        font-size: 16px;
                        font-weight: bold;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }

            .btn-group {
                // position: absolute;
                display: flex;
                align-items: center;
                // right: 12px;
                // bottom: 15px;
                height: 120px;
                margin-left: 8px;
                margin-right: 30px;
            }
        }
    }
    .group-course-list {
        flex: 6;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // background-color: #f9f8f2;

        .course-list {
            flex: 1;
            // height: 125px;

            .list-group-headers {
                height: 48px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #eee;
                font-size: 14px;
                font-weight: bold;
                color: #555;
                
                .index {
                    width: 60px;
                }
                .name {
                    flex: 1;
                    text-align: center;
                }
                .desc {
                    flex: 1;
                    text-align: left;
                }
                .status {
                    width: 100px;
                }
                .type {
                    width: 60px;
                }
                .op {
                    width: 120px;
                }
            }

            .list-group {
                height: 100%;
                align-items: flex-start;

                .list-group-item {
                    text-align: left;
                    height: 55px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #eee;
                    .group-item {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        // padding-left: 20px;
                        .handle {
                            width: 60px;
                            cursor: move;
                        }
                        .index {
                            // margin-right: 8px;
                            // color: #fff;
                            // font-weight: bold;
                            // background-color: #333;
                            width: 60px;
                            height: 22px;
                            border-radius: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .name {
                            flex: 1;
                            font-size: 14px;
                            font-weight: bold;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            .active-name {
                                padding: 8px 25px;
                                border-radius: 50px;
                                // background-color: #efefef;
                                background-color: #fff;
                                &:hover {
                                    cursor: pointer;
                                    background-color: #fff;
                                    border-radius: 50px;
                                }
                            }
                        }
                        .desc {
                            flex: 1;
                            font-size: 14px;
                        }
                        .status {
                            width: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .type {
                            width: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .op {
                            width: 120px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .item-op {
                        width: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    
                    &:hover {
                        // background-color: #ebebeb;
                        background-color: #F5F5F5;
                    }
                }
            }
        }
    }
}
</style>
